
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import DefaultLayout from '@/components/DefaultLayout.vue';
import { AdminService } from '../../services/admin-service';
const namespace: string = 'profile';
@Component({
  components: { DefaultLayout }
})
export default class ManageCache extends Vue {
  @Action('logout', { namespace }) logout: any;
  $refs!: {
    defaultActions: HTMLFontElement;
  };
  @Action('login', { namespace })
  login: any;
  cacheListItems: any = [
    {
      title: 'Refresh dies',
      subtitle: 'Refresh the die Services',
      isChecked: false
    },
    {
      title: 'Refresh the materials',
      subtitle: 'Refresh the Materials singleton',
      isChecked: false
    },
    {
      title: 'Refresh the Customer',
      subtitle: 'Refresh the customer cache',
      isChecked: false
    },
    {
      title: 'Refresh the pricing',
      subtitle: 'Refresh the pricing cache',
      isChecked: false
    },
    {
      title: 'Refresh ALL Caches',
      subtitle: 'Refresh All the Caches',
      isChecked: false
    }
  ];
  isRefreshingCache: boolean = false;
  async refreshCache() {
    this.isRefreshingCache = true;
    if (this.cacheListItems[0].isChecked) {
      await AdminService.refreshDieCache();
    }
    if (this.cacheListItems[1].isChecked) {
      await AdminService.refreshMaterial();
    }
    if (this.cacheListItems[2].isChecked) {
      await AdminService.refreshCustomerCache();
    }
    if (this.cacheListItems[3].isChecked) {
      await AdminService.refreshPricingCaches();
    }
    if (this.cacheListItems[4].isChecked) {
      await AdminService.refreshAllCaches();
    }
    SetSnackBar('Cache(s) have been refreshed');
    this.isRefreshingCache = false;
  }
}
